<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <!-- <div title="课程要求总学时" class="searchboxItem">
          <span class="itemLabel">课程要求总学时:</span>
          <span>{{50}}</span>
        </div>-->
      </div>
      <div class="btnBox">
        <el-button type="primary" class="bgc-bv" round @click="handleAdd()">新增课程</el-button>
        <el-button type="primary" class="bgc-bv" round @click="prev()" :disabled="goType == 'copyClass'">上一步</el-button>
        <el-button type="primary" class="bgc-bv" round v-if="tableData.length != 0" @click="next()">下一步</el-button>
      </div>
    </div>
    <div style="padding: 10px 0.675rem 0 0.675rem;color:red;">{{ errMsg }}</div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
          <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" width="200" />
          <el-table-column v-if="projectSource == '20' && trainTypeId == '16'" label="工种" align="center"
            show-overflow-tooltip prop="sourceIndustryName" width="100" />
          <el-table-column label="要求完成总课件数" align="center" show-overflow-tooltip prop="coursewareTotalNum" />
          <el-table-column label="视频累计时长" align="center" show-overflow-tooltip prop="kpointDurationStr" />
          <el-table-column label="上课学员数" align="center" show-overflow-tooltip prop="studyUserCount">
            <template slot-scope="scope">
              {{ scope.row.studyUserCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="已完成学员数" align="center" show-overflow-tooltip prop="completeUserCount">
            <template slot-scope="scope">
              {{ scope.row.completeUserCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="培训进度" align="center" prop="progress">
            <template slot-scope="scope">
              <el-progress :percentage="(parseInt(scope.row.progress * 100))"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="350px">
            <div slot-scope="scope" class="flexcc">
              <el-button type="text" size="mini" style="padding:0 5px"
                @click="see(scope.row.projectCourseId, scope.row.courseId, 'stus')">查看</el-button>
              <el-button type="text" size="mini" style="padding:0 5px"
                :disabled="auditState == '30' || auditState == '20' || auditState == '25'"
                @click="replace(scope.row.projectCourseId, scope.row.courseId)">替换</el-button>
              <el-button type="text" size="mini" style="padding:0 5px"
                :disabled="auditState == '30' || auditState == '20' || auditState == '25'"
                @click="handleDel(scope.row.projectCourseId)">删除</el-button>
              <!-- <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                @click="
                  goDisinfectionRecord(scope.row.courseId, scope.row.auditState)
                "
                >查看评价</el-button
              >-->
              <el-button type="text" size="mini" style="padding:0 5px" :disabled="auditState == '30'"
                @click="Setexamination(scope.row)" v-if="!scope.row.paperId">设置结业考试</el-button>
              <el-button v-else type="text" size="mini" style="padding:0 5px"
                @click="Setexamination(scope.row)">查看结业考试</el-button>
              <el-button type="text" size="mini" style="padding:0 5px"
                @click="StudentsInClass(scope.row.projectCourseId)">上课学员</el-button>
              <el-button type="text" size="mini" style="padding:0 5px"
                :disabled="auditState == '30' || auditState == '20' || auditState == '25' || trainTypeId == '2'"
                @click="relevanceUser(scope.row.projectCourseId)">关联所有学员</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog title="结业考试" :visible.sync="dialogExamination" width="600px" top="15%" :center="true"
      :before-close="doClose">
      <div style="display:flex; flex-direction: column;">
        <div>
          <span>考试名称：</span>
          <span>{{ paperName }}</span>
        </div>
        <div style="padding-top: 15px">
          <span>通过分数：</span>
          <span>{{ paperScore }}分</span>
        </div>
        <div style="padding-top: 15px">
          <span>考试时间：</span>
          <span v-if="paperStartTime">{{ paperStartTime | momentWu }}至{{ paperEndTime | momentWu }}</span>
          <span v-else>未设置考试时间</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-between;">
        <div>
          <el-button @click="cancle">取消</el-button>
        </div>
        <div class="btnBox">
          <el-button class="bgc-bv" @click="Examinationedit"
            :disabled="auditState == '30' || auditState == '20'">修改</el-button>
          <el-button @click="paperdelete" :disabled="auditState == '30' || auditState == '20'">删除</el-button>
        </div>
      </span>
    </el-dialog>
    <Mytestpaper ref="Mytestpaper" @eventBus="tableBack" @getData="getData" />
    <el-dialog class="del-tishi" title="提示" :visible.sync="dialogVisible" width="20%" :before-close="cancelCountdown">
      <span>
        你确定要删除该课程吗？删除课程后，该课的上课学员将会从本班移除，你可以通过课程替换功能修改课程。
        <br />请仔细阅读以上提示，
        <span class="theCountdown">{{ countdown }}</span>秒后可操作！
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCountdown">取 消</el-button>
        <el-button class="bgc-bv" @click="cancelYes" :disabled="deleteConfirmation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import Mytestpaper from "@/views/classListCom/classDetailsFile/popup/Mytestpaper";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    Mytestpaper
  },
  mixins: [List],
  data() {
    return {
      delectId: "",
      userJson: {},
      queryStr: "",
      dialogVisible: false,
      dialogExamination: false,
      dialogMytestpaper: false,
      activeName: "first",
      ruleForm: {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: ""
      },
      projectId: "",
      affirm: 0,
      paperTotalScore: "",
      paperName: "",
      paperScore: "",
      paperStartTime: "",
      paperEndTime: "",
      projectCourseId: "",
      paperId: "",
      projectSource: "",
      countdown: "10",
      deleteConfirmation: true,
      timer: null,
      trainTypeId: "",
      typeofWorkList: [],
      disTrue: false
    };
  },
  props: {
    goType: {
      type: String,
      default: ""
    },
    errMsg: {
      type: String,
      default: ""
    }
  },
  computed: {},
  methods: {
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.auditState = sessionStorage.getItem("auditState");
      this.projectSource = sessionStorage.getItem("projectSource");
      this.trainTypeId = sessionStorage.getItem("trainTypeId");
      this.getTableHeight();
      this.getData(-1);
      this.disTrue = false;
      if (this.trainTypeId == '16' && this.projectSource == '20') {
        this.disTrue = true;
        this.getTreeTrain()
      }
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId
      };
      this.doFetch({
        url: "/biz/projectCourse/listPage",
        params,
        pageNum
      });
    },
    handleListDataBack(tableData) {
      this.tableData = tableData;
      sessionStorage.setItem("courseCount", this.tableData.length);
      // sessionStorage.setItem("refresh", 10);
    },
    see(projectCourseId, courseId, stus) {
      this.$router.push({
        path: "/web/seeClassMinsCtr",
        query: {
          projectCourseId,
          auditState: this.auditState,
          courseId,
          stus: stus
        }
      });
    },
    // 创建课程、查看编辑课程
    handleAdd() {
      this.$router.push({
        path: "/web/quickAddCourseBox"
      });
    },
    replace(projectCourseId, courseId) {
      this.$router.push({
        path: "/web/quickAddCourseBox",
        query: {
          projectCourseId,
          courseId
        }
      });
    },
    // 删除
    handleDel(id) {
      this.countdown = 10;
      this.delectId = id;
      this.dialogVisible = true;
      this.deleteConfirmation = true;
      this.timer = setInterval(() => {
        --this.countdown;
        if (this.countdown == 0) {
          clearInterval(this.timer);
          this.deleteConfirmation = false;
        }
      }, 1000);
    },
    //确定删除
    cancelYes() {
      this.dialogVisible = false;
      this.$post("/biz/projectCourse/deleteProjectCourse", {
        projectCourseId: this.delectId
      }).then(res => {
        this.delectId = "";
        this.getData();
        this.disTrue = false
        setTimeout(() => {
          sessionStorage.setItem("courseCount", this.tableData.length);
          console.log(this.tableData.length);
        }, 1000);
      });
      this.init();
      clearInterval(this.timer);
    },
    //点击取消
    cancelCountdown() {
      (this.countdown = "10"),
        (this.dialogVisible = false),
        clearInterval(this.timer);
    },
    tableBack() {
      this.cancle();
    },
    // 设置课后考试
    Setexamination(row) {
      if (this.auditState == "30" || this.auditState == "20") {
        if (row.paperId) {
          this.dialogExamination = true;
          this.paperScore = row.paperScore;
          this.paperName = row.paperName;
          this.paperStartTime = row.paperStartTime;
          this.paperEndTime = row.paperEndTime;
          this.projectCourseId = row.projectCourseId;
          this.paperId = row.paperId;
        } else {
          this.$alert("该课程未设置结业考试", "提示", {
            confirmButtonText: "确定",
            confirmButtonClass: "confirmButtonClass",
            callback: action => { }
          });
        }
      } else {
        if (row.paperId) {
          this.dialogExamination = true;
          this.paperScore = row.paperScore;
          this.paperName = row.paperName;
          this.paperStartTime = row.paperStartTime;
          this.paperEndTime = row.paperEndTime;
          this.projectCourseId = row.projectCourseId;
          this.paperTotalScore = row.paperTotalScore;
          this.paperId = row.paperId;
        } else {
          this.$refs.Mytestpaper.showPopUp(
            row.paperTotalScore,
            row.projectCourseId,
            row.paperScore,
            row.paperName,
            row.paperId,
            row.paperStartTime,
            row.paperEndTime
          );
        }
      }
    },
    paperdelete() {
      this.$confirm("是否删除结业考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButtonClass",
        type: "warning"
      })
        .then(() => {
          this.$post("/biz/projectCourse/deleteProjectCoursePaper", {
            projectCourseId: this.projectCourseId
          })
            .then(ret => {
              this.dialogExamination = false;
              this.$message({
                message: ret.message,
                type: "success"
              });
              this.getData(-1);
            })
            .catch(err => {
              return;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    Examinationedit() {
      this.$refs.Mytestpaper.showPopUp(
        this.paperTotalScore,
        this.projectCourseId,
        this.paperScore,
        this.paperName,
        this.paperId,
        this.paperStartTime,
        this.paperEndTime
      );
    },
    // 上课学员
    StudentsInClass(projectCourseId) {
      sessionStorage.setItem("projectCourseId", projectCourseId);
      this.$router.push({
        path: "/web/StudentsInClass"
      });
    },
    relevanceUser(projectCourseId) {
      this.$post("/biz/projectCourse/relevance/user", {
        projectCourseId: projectCourseId
      }).then(res => {
        if (res.status == 0) {
          this.$message({
            message: "关联学员成功",
            type: "success",
          });
        }
      });
    },
    //取消
    cancle() {
      this.dialogExamination = false;
    },
    canclepaper() {
      this.dialogExamination = false;
    },
    doClose() {
      this.dialogExamination = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTreeTrain() {
      this.$post("/biz/sou/cc/querySourceProjectCourseList", {
        projectId: sessionStorage.getItem("projectId"),
        isAll: true,
      }).then((ret) => {
        this.typeofWorkList = ret.data;
        if (this.typeofWorkList.length == this.tableData.length) {
          this.disTrue = true
        }
      });
    },
    next() {
      this.$emit("eventBus", 2);
    },
    prev() {
      this.$emit("eventBusprev", 0);
    }

  }
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.btnBox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.bgc-bv:hover,
.bgc-bv:focus,
.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  background-color: #5c6be8 !important;
}

.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }

  .el-dialog__title {
    color: #333;
  }

  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
